// src/pages/Home.js
import React, { useState, useEffect } from "react";
import { useTheme } from "@emotion/react";
import {
    Typography,
    Box,
    TextField,
    Button,
    Grid,
    FormControl,
    MenuItem,
    InputLabel,
    Select,
    IconButton,
    Checkbox,
FormGroup,
    FormControlLabel,
  } from "@mui/material";

import HomeGrid from "../components/homeGrid";
import MembershipDialog from "../components/dialog/membershipDialog";

const Pricing = () => {
  const theme = useTheme();
  const [openMembershipDialog, setOpenMembershipDialog] = useState(false);

  useEffect(() => {
    setOpenMembershipDialog(true);
  }, []);


  return (
    <div sx={{
      "& .MuiButton-root": {
        backgroundColor: theme.palette.text.third,
      },
    }}>   
        <Typography variant="h3" color="white" textAlign="center" mx={3} mt={3}>
            Welcome to the Coverd App!
        </Typography>
        <Typography variant="h6" textAlign="center" color="white" mt={3}>
            Top tickers our users search for and their ROIs:
        </Typography>
        <Box mt={3} sx={{"@media (min-width: 600px)": {
            width: "auto", 
          },}}>
            <HomeGrid />
        </Box>

        <MembershipDialog open={openMembershipDialog} onClose={() => setOpenMembershipDialog(false)} />
    </div>
  );
};

export default Pricing;
