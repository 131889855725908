import React, { useState } from "react";
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  OutlinedInput,
  InputLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useTheme } from "@emotion/react";
import { changeEmail, changePassword, deleteAccount } from "../actions/authActions";
import CustomAlert2 from "../components/customAlert2";
import { useDispatch } from "react-redux";
import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const ManageAccount = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { user, logout, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [accountChange, setAccountChange] = useState(false);
  const [accountChangeFailed, setAccountChangeFailed] = useState(false);
  const [accountDelete, setAccountDelete] = useState(false);
  const [passwordChange, setPasswordChange] = useState(false);
  const [failedPassword, setFailePassword] = useState(false);
  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword1, setShowPassword1] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);

  const fieldsetStyle = {
    width: "100%",
    margin: "auto",
    borderColor: theme.palette.text.third,
    borderRadius: 25,
    marginTop: "5vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(1),
  };

  const handleBillingAccount = () => {
    // dispatch(createPortalSession({ user: user }));
  };

  const handleChangeEmail = () => {
    setOpenEmailDialog(true);
  };

  const confirmChangeEmail = () => {
    const newUsername = newEmail.split('@')[0];
    dispatch(changeEmail({ userId: user.sub, email: newEmail, oldEmail: user.email, username: newUsername }, emailChange, failedEmailChange));
    setOpenEmailDialog(false);
  };

  const emailChange = () => {
    setAccountChange(true);
    logout({ logoutParams: { returnTo: window.location.origin } });
  }

  const failedEmailChange = () => {
    setAccountChangeFailed(true);
  }

  const handleChangePassword = () => {
    setOpenPasswordDialog(true);
  };

  const confirmChangePassword = () => {
    if (validatePassword(newPassword)) {
      if (newPassword === confirmPassword) {
        dispatch(changePassword({ password: newPassword, userId: user.sub, oldEmail: user.email }, passwordChangeSuccess, passwordChangeFailed));
        setOpenPasswordDialog(false);
      } else {
        setPasswordError("Passwords do not match.");
      }
    } else {
      setPasswordError("Password is too weak. It must be at least 8 characters long and include a number and a special character.");
    }
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
    return passwordRegex.test(password);
  };

  const handleDeleteAccount = () => {
    setOpenDialog(true);
  };

  const confirmDeleteAccount = async () => {
    dispatch(deleteAccount({ userId: user.sub, oldEmail: user.email }, logOutSuccess));
  };

  const logOutSuccess = () => {
    handleCloseDialog();
    setAccountDelete(true);
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const passwordChangeSuccess = () => {
    setPasswordChange(true);
  };

  const passwordChangeFailed = () => {
    setFailePassword(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseEmailDialog = () => {
    setOpenEmailDialog(false);
  };

  const handleClosePasswordDialog = () => {
    setOpenPasswordDialog(false);
    setPasswordError("");
    setNewPassword("");
    setConfirmPassword("");
  };

  return (
    <section>
      <Box
        textAlign="center"
        sx={{
          width: "80vw",
          margin: "auto",
          "@media (min-width: 600px)": {
            width: "25%",
          },
        }}
      >
        <Typography variant="h6" textAlign="left" mx={2} mt={15} sx={{ color: "white" }}>
          Manage Account
        </Typography>
        <fieldset style={fieldsetStyle}>
          <List sx={{ width: "100%" }}>
            <ListItem button onClick={handleBillingAccount}>
              <ListItemText primary="Billing Account (Stripe)" sx={{ textAlign: "center" }} />
            </ListItem>
            <ListItem button onClick={handleChangeEmail}>
              <ListItemText primary="Change Email" sx={{ textAlign: "center" }} />
            </ListItem>
            <ListItem button onClick={handleChangePassword}>
              <ListItemText primary="Change Password" sx={{ textAlign: "center" }} />
            </ListItem>
            <ListItem button onClick={handleDeleteAccount} sx={{ marginTop: "20px!important" }}>
              <ListItemText primary="Delete Account" sx={{ textAlign: "center", color: "red" }} />
            </ListItem>
          </List>
        </fieldset>
      </Box>

      <Dialog open={openDialog} onClose={handleCloseDialog} PaperProps={{
          style: {
            backgroundColor: theme.palette.background.paper,
            borderRadius: theme.shape.borderRadius,
            boxShadow: theme.shadows[5],
            width: "360px"
          },
        }}> 
      <DialogTitle sx={{ backgroundColor: "white", color: "#464646", textAlign: "center", fontWeight: "700" }}>
        <Box sx={{ textAlign: "center", mb: 2 }}>
          <Box
            component="img"
            src="https://earn.coverd.io/logo2.png"
            sx={{ display: "block", margin: "0 auto", width: "124px", marginTop: "40px", marginBottom: "20px" }}
            alt="Auth0"
            
          />
        </Box>
          {/* <img src="https://static-00.iconduck.com/assets.00/auth0-icon-458x512-8spyi0ve.png" sx={{display: "block", margin: "0 auto", width: "60px"}} /> */}
          {"Delete Email"}
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: "#fff" }}>
          <DialogContentText sx={{ color: "#8a8a8a", textAlign: "center", padding: "30px 50px", fontSize: "12px" }}>
            Do you really want delete your account?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: "#fff", justifyContent: "center" }}>
          <Button onClick={confirmDeleteAccount} color="primary" sx={{ border: "2px solid white", borderRadius: "30px", padding: "10px 50px", background: "#bfa76f", color: "#fff", marginBottom:"30px"}}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openEmailDialog}
        onClose={handleCloseEmailDialog}
        PaperProps={{
          style: {
            backgroundColor: theme.palette.background.paper,
            borderRadius: theme.shape.borderRadius,
            boxShadow: theme.shadows[5],
            width: "360px"
          },
        }}
      >
        <DialogTitle sx={{ backgroundColor: "#fff", color: "#464646", textAlign: "center", fontWeight: "700" }}>
          <Box sx={{ textAlign: "center", mb: 2 }}>
            <Box
              component="img"
              src="https://earn.coverd.io/logo2.png"
              sx={{ display: "block", margin: "0 auto", width: "124px", marginTop: "40px", marginBottom: "20px" }}
              alt="Auth0"
            />
          </Box>
          {"Change Email"}
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: "#fff" }}>
          <DialogContentText sx={{ color: "#8a8a8a", textAlign: "center", padding: "30px 50px", fontSize: "12px" }}>
            Enter your new email:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="newEmail"
            label="New Email"
            type="email"
            // width="100%"
            fullWidth
            variant="outlined"
            onChange={(e) => setNewEmail(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'rgb(191, 167, 111)', // Default border color
                },
                '&:hover fieldset': {
                  borderColor: 'rgb(191, 167, 111)!important', // Hover border color
                  backgroundColor: 'transparent!important'
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'rgb(191, 167, 111)', // Focused border color
                  backgroundColor: 'transparent!important'
                },
              },
              
              '& .MuiInputLabel-root.Mui-focused': {
                color: 'rgb(191, 167, 111)', // Focused label color
              },
              '& .MuiInput-underline:after': {
                borderBottomColor: '#B2BAC2',
              },
              '& .MuiInputBase-input': {
                backgroundColor: 'transparent!important', // Make the input background transparent
                WebkitBoxShadow: '0 0 0 100px #ffffff inset!important',
                WebkitTextFillColor: 'black!important'
              },
            }}
          />
        </DialogContent>
        <DialogActions sx={{ backgroundColor: "#fff", justifyContent: "center" }}>
          <Button onClick={confirmChangeEmail} color="primary" sx={{ border: "2px solid white", borderRadius: "30px", padding: "10px 50px", background: "#bfa76f", color: "#fff", marginBottom:"30px"}}>
            Change Email
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openPasswordDialog} onClose={handleClosePasswordDialog} PaperProps={{
          style: {
            backgroundColor: theme.palette.background.paper,
            borderRadius: theme.shape.borderRadius,
            boxShadow: theme.shadows[5],
            width: "360px"
          },
        }}>
        <DialogTitle sx={{ backgroundColor: "#fff", color: "#464646", textAlign: "center", fontWeight: "700" }}>
        <Box sx={{ textAlign: "center", mb: 2 }}>
          <Box
            component="img"
            src="https://earn.coverd.io/logo2.png"
            sx={{ display: "block", margin: "0 auto", width: "124px", marginTop: "40px", marginBottom: "20px" }}
            alt="Auth0"
          />
        </Box>
          {"Change Your Password"}
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: "#fff" }}>
          <DialogContentText sx={{ color: "black", textAlign: "center", padding: "10px 50px", fontSize: "12px" }}>
            Enter your a new password to change your
            password
          </DialogContentText>
          
          <TextField
            autoFocus
            margin="dense"
            id="newPassword"
            label="New Password*"
            type={showPassword ? 'text' : 'password'}
            fullWidth
            variant="outlined"
            // value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            error={!!passwordError}
            helperText={passwordError}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'rgb(191, 167, 111)', // Default border color
                },
                '&:hover fieldset': {
                  borderColor: 'rgb(191, 167, 111)!important', // Hover border color
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'rgb(191, 167, 111)', // Focused border color
                },
              },
              '& .MuiInputLabel-root': {
                
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: 'rgb(191, 167, 111)', // Focused label color
              },
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">
              <IconButton
                aria-label={
                  showPassword ? 'hide the password' : 'display the password'
                }
                onClick={handleClickShowPassword}
                // onMouseDown={handleMouseDownPassword}
                // onMouseUp={handleMouseUpPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>,
            }}
          />
          <TextField
            margin="dense"
            id="confirmPassword"
            label="Re-enter new password*"
            type={showPassword1 ? 'text' : 'password'}
            fullWidth
            variant="outlined"
            // value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'rgb(191, 167, 111)', // Default border color
                },
                '&:hover fieldset': {
                  borderColor: 'rgb(191, 167, 111)!important', // Hover border color
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'rgb(191, 167, 111)', // Focused border color
                },
              },
              '& .MuiInputLabel-root': {
                
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: 'rgb(191, 167, 111)', // Focused label color
              },
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">
              <IconButton
                aria-label={
                  showPassword1 ? 'hide the password' : 'display the password'
                }
                onClick={handleClickShowPassword1}
                // onMouseDown={handleMouseDownPassword}
                // onMouseUp={handleMouseUpPassword}
                edge="end"
              >
                {showPassword1 ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>,
            }}
          />
        </DialogContent>
        <DialogActions sx={{ backgroundColor: "#fff", justifyContent: "center" }}>
          <Button onClick={confirmChangePassword} color="primary" sx={{ border: "2px solid white", borderRadius: "30px", padding: "10px 50px", background: "#bfa76f", color: "#fff", marginBottom:"30px"}}>
            Reset Password
          </Button>
        </DialogActions>
      </Dialog>

      <Box display="flex" justifyContent="center" mt={10}>
        <CustomAlert2
          openState={accountChange}
          severity="success"
          text="Email has been changed. Reloing with new email"
          autoHideDuration={3000}
          onClose={() => {
            setAccountChange(false);
          }}
        />

        <CustomAlert2
          openState={accountDelete}
          severity="success"
          text="Account has been deleted."
          autoHideDuration={3000}
          onClose={() => {
            setAccountDelete(false);
          }}
        />

        <CustomAlert2
          openState={passwordChange}
          severity="success"
          text="Password has been changed."
          autoHideDuration={3000}
          onClose={() => {
            setPasswordChange(false);
          }}
        />

        <CustomAlert2
          openState={failedPassword}
          severity="error"
          text="You can't change password. It could be password's strength is weak or you signup with Google account, If you want change your password, you must signup with Username-Password-Authentication method."
          autoHideDuration={5000}
          onClose={() => {
            setFailePassword(false);
          }}
        />

        <CustomAlert2
          openState={accountChangeFailed}
          severity="error"
          text="You can't change email. It could be email is not correct or you signup with Google account, If you want change your email, you must signup with Username-Password-Authentication method."
          autoHideDuration={5000}
          onClose={() => {
            setAccountChangeFailed(false);
          }}
        />
      </Box>
    </section>
  );
};

export default ManageAccount;
